import ContentArea from 'DesignComponents/Organisms/ContentArea/ContentArea';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import CampaignEnded from 'Commerce/Molecules/CampaignEnded/CampaignEnded';
import MagazineCategoryPageModel from 'Models/Pages/MagazineCategoryPage/MagazineCategoryPageModel.interface';
import HeroSection from 'DesignComponents/Organisms/HeroSection/HeroSection';

function MagazinePage() {
  const {
    hero,
    contentArea,
    specialOfferImage,
    inEditMode,
    campaignHasEnded,
    campaignHasEndedBodyText,
    campaignHasEndedHeaderText,
  } = useCurrentPage<MagazineCategoryPageModel>();

  return (
    <>
      <HeroSection hero={hero} specialOfferImage={specialOfferImage} />
      {campaignHasEnded && (
        <ContentContainer>
          <CampaignEnded
            headerText={campaignHasEndedHeaderText}
            text={campaignHasEndedBodyText}
          />
        </ContentContainer>
      )}
      <ContentContainer {...applyEditModeAttr(inEditMode && 'MainContent')}>
        {Array.isArray(contentArea) && <ContentArea childItems={contentArea} />}
      </ContentContainer>
    </>
  );
}

export default MagazinePage;
