import ImageModel from 'Models/Assets/ImageModel.interface';
import HeroModule from '../Hero/HeroModule';
import SpecialOfferImage from 'Commerce/Atom/SpecialOfferImage/SpecialOfferImage';
import HeroModel from 'Models/Addons/HeroModel.interface';

type PropsType = {
  hero: HeroModel;
  specialOfferImage: ImageModel;
};

function HeroSection({ hero, specialOfferImage }: PropsType) {
  return (
    <>
      {specialOfferImage && specialOfferImage.src ? (
        <SpecialOfferImage
          specialOfferImage={specialOfferImage}
          heading={hero.heading}
          promotionText={hero.heroText}
        />
      ) : hero ? (
        <HeroModule hero={hero} />
      ) : null}
    </>
  );
}
export default HeroSection;
