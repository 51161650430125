import { styled } from '@stitches/react';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import ImageModel from 'Models/Assets/ImageModel.interface';
import useImageUrl from 'Shared/Hooks/useImageUrl';

type SpecialOfferImageType = {
  specialOfferImage: ImageModel;
  heading?: string;
  promotionText?: string;
};

function SpecialOfferImage({
  specialOfferImage,
  heading,
  promotionText = '',
}: SpecialOfferImageType) {
  const imageUrl = useImageUrl(specialOfferImage);

  return (
    <>
      {imageUrl && (
        <SpecialOfferImageContainer>
          <img src={imageUrl} />
        </SpecialOfferImageContainer>
      )}

      <ContentContainer>
        <Heading tag="h1" size={'l'} css={headingStyles}>
          {heading}
        </Heading>

        <TextBlockContainer
          dangerouslySetInnerHTML={{ __html: promotionText }}
        ></TextBlockContainer>
      </ContentContainer>
    </>
  );
}

const headingStyles = {
  mb: 5,
};

const SpecialOfferImageContainer = styled('div', {
  maxWidth: '$screenMaxWidth',
  margin: '0 auto',
  marginBottom: '$s400',
  '& img': {
    width: '100%',
  },
});

const TextBlockContainer = styled('div', {
  color: '$onSurface',
  lineHeight: '$lh24',
  '@media (min-width: 769px)': {
    maxWidth: '70%',
  },
});

export default SpecialOfferImage;
